import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import HomeTabContextProvider from "../context/HomeTabContext";

// import Footer2 from "../components/Footer/Footer2";
import FooterRedux from "../components/Footer/footer_rework";
import Header from "../components/Navbar/Header";
import PageNotFound from "../components/PageNotFound";
import addTeam from "../components/admin/addTeam";
import viewTeam from "../components/admin/viewTeam";
import viewContact from "../components/admin/ViewContact";
import ProjectContact from "../components/admin/ProjectContact";
import editTeam from "../components/admin/edit-team";
import News from "../components/Media/News";
import PressReleases from "../components/Media/PressReleases";
import PortfolioSpotlights from "../components/Media/PortfolioSpotlights";
import Edge196Twitter from "../components/Media/Edge196Twitter";
import NewsAndPress from "../components/admin/NewsAndPress";

/**
 * Auth Routes
 */
const Login = React.lazy(() => import("../pages/Auth/LoginPage"));
const Register = React.lazy(() => import("../pages/Auth/RegisterPage"));
const Forget = React.lazy(() => import("../pages/Auth/ForgetPassword"));
const Activate = React.lazy(() => import("../pages/Auth/ActivatePage"));
const Reset = React.lazy(() => import("../pages/Auth/ResetPage"));
const TwoFA = React.lazy(() => import("../pages/Auth/TwoFA"));

/**
 * Dashboard Routes
 */
const SwapPage = React.lazy(() => import("../pages/Dashboard/SwapPage"));
const PoolPage = React.lazy(() => import("../pages/Dashboard/PoolPage"));
const PoolPageMobile = React.lazy(() =>
  import("../pages/Dashboard/PoolPageMobile")
);
const KycPage = React.lazy(() => import("../pages/Dashboard/KycPage"));
const StakingPage = React.lazy(() => import("../pages/Dashboard/StakingPage"));
const Settings = React.lazy(() => import("../pages/Dashboard/Settings"));
const BiddingHistoryPage = React.lazy(() =>
  import("../pages/Dashboard/BiddingHistory")
);
const AuctionPage = React.lazy(() => import("../pages/Dashboard/AuctionPage"));
const WalletPage = React.lazy(() => import("../pages/Dashboard/WalletPage"));

/**
 * CMS Routes
 */
const OurStoryPage = React.lazy(() => import("../pages/CMSPage/OurStoryPage"));
const OurSolutiomPage = React.lazy(() =>
  import("../pages/CMSPage/OurSolutiomPage")
);
const TeamPage = React.lazy(() => import("../pages/CMSPage/TeamPage"));
const ProjectPage = React.lazy(() => import("../pages/CMSPage/ProjectPage"));
const TermsOfUsePage = React.lazy(() =>
  import("../pages/CMSPage/TermsOfUsePage")
);
const LegalDisclaimer = React.lazy(() =>
  import("../components/legalDisclamer")
);
const PrivacyPolicyPage = React.lazy(() =>
  import("../pages/CMSPage/PrivacyPolicyPage")
);
const CookiePolicyPage = React.lazy(() =>
  import("../pages/CMSPage/CookiePolicyPage")
);
const PurchaseAgreementPage = React.lazy(() =>
  import("../pages/CMSPage/PurchaseAgreementPage")
);
const EventsPage = React.lazy(() => import("../pages/CMSPage/EventsPage"));
const AsiaBlockchainPage = React.lazy(() =>
  import("../pages/CMSPage/AsiaBlockchainPage")
);
const PartnersPage = React.lazy(() => import("../pages/CMSPage/PartnersPage"));
const AmbassadorPage = React.lazy(() =>
  import("../pages/CMSPage/AmbassadorPage")
);
const ContactUsPage = React.lazy(() =>
  import("../pages/CMSPage/ContactUsPage")
);
const WorkWithUsPage = React.lazy(() =>
  import("../pages/CMSPage/WorkWithUsPage")
);
const FaqsPage = React.lazy(() => import("../pages/CMSPage/FaqsPage"));
const MediaPage = React.lazy(() => import("../pages/CMSPage/MediaPage"));
const HomePageV2 = React.lazy(() => import("../pages/CMSPage/HomePageV2"));

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/two-factor-authentication" component={TwoFA} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot-password" component={Forget} />
        <Route exact path="/activate" component={Activate} />
        <Route exact path="/reset/:id" component={Reset} />

        <Route exact path="/dashboard/universal-swap" component={SwapPage} />
        <Route exact path="/dashboard/genesis-offer" component={PoolPage} />
        <Route exact path="/dashboard/payment" component={PoolPageMobile} />
        <Route exact path="/dashboard/kyc" component={KycPage} />
        <Route exact path="/dashboard/power-staking" component={StakingPage} />
        <Route exact path="/dashboard/settings" component={Settings} />
        <Route exact path="/dashboard/momentum-sale" component={AuctionPage} />
        <Route
          exact
          path="/dashboard/momentum-sale/history"
          component={BiddingHistoryPage}
        />
        <Route exact path="/dashboard/wallet" component={WalletPage} />
        <Redirect from="/dashboard" to="/dashboard/wallet" />
        <HomeTabContextProvider>
          <div className="n_web">
            <Header />
            <Switch>
              <Route exact path="/" component={HomePageV2} />
              <Route exact path="/our-story" component={OurStoryPage} />
              <Route exact path="/our-solution" component={OurSolutiomPage} />
              {/* <Route exact path="/team" component={TeamPage} /> */}
              <Route exact path="/project" component={ProjectPage} />
              <Route exact path="/terms-of-use" component={TermsOfUsePage} />
              <Route
                exact
                path="/legal-disclaimer"
                component={LegalDisclaimer}
              />
              <Route
                exact
                path="/privacy-policy"
                component={PrivacyPolicyPage}
              />
              <Route exact path="/cookie-policy" component={CookiePolicyPage} />
              <Route
                exact
                path="/purchase-agreement"
                component={PurchaseAgreementPage}
              />
              <Route exact path="/events" component={EventsPage} />
              <Route
                exact
                path="/asia-blockchain"
                component={AsiaBlockchainPage}
              />
              {/* <Route exact path="/partners" component={PartnersPage} /> */}
              <Route exact path="/ambassador" component={AmbassadorPage} />
              <Route exact path="/contact-us" component={ContactUsPage} />
              <Route exact path="/work-with-us" component={WorkWithUsPage} />
              {/* <Route exact path="/faqs" component={FaqsPage} /> */}
              <Route exact path="/media" component={MediaPage} />
              {/* <Route exact path="/news" component={News} /> */}
              <Route exact path="/press-releases" component={PressReleases} />
              <Route
                exact
                path="/portfolio-spotlights"
                component={PortfolioSpotlights}
              />
              <Route
                exact
                path="/edge196-on-twitter"
                component={Edge196Twitter}
              />
              <Route exact path="/admin/add-team" component={addTeam} />
              <Route exact path="/admin/view-team" component={viewTeam} />
              <Route exact path="/admin/modify/:id" component={editTeam} />
              <Route exact path="/admin/view-contact" component={viewContact} />
              <Route
                exact
                path="/admin/project-contact"
                component={ProjectContact}
              />
              <Route
                exact
                path="/admin/news-and-press-release"
                component={NewsAndPress}
              />
              <Route component={PageNotFound} />
            </Switch>
            {/* <Footer2 /> */}
            <FooterRedux />
          </div>
        </HomeTabContextProvider>
      </Switch>
    </Router>
  );
};

export default Routes;
